import React, { Component } from 'react';
import axios from 'axios';
import ProgressMobileStepper from './ProgressMobileStepper';
import { Typography } from '@material-ui/core';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import Box from '@material-ui/core/Box'
import TopBar from 'src/layouts/MainLayout/TopBar';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';

const API_URL = process.env.REACT_APP_API_BASE_URL;

function customShuffle(arr) {
    for (let i = arr.length - 3; i >= 0; --i) {
        const j = 1 + Math.floor(Math.random() * i);
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

function shuffleArray(array, startingIndex, fixedIndex, endingIndex) {
    for (let currentIndex = startingIndex; currentIndex < endingIndex; currentIndex++) {
      let randomIndex = startingIndex + (Math.floor(Math.random() * (endingIndex - startingIndex)));
      if(fixedIndex === currentIndex || fixedIndex === randomIndex) continue;
      if(currentIndex > endingIndex || randomIndex > endingIndex) continue;
      let tempValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = tempValue;
    }
    return array;
  }

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            lang: props.lang,
            languages: [],
            questions: [],
            answers: [],
            ro: props.ro,
            status: '',
            varMap: new Map(),
            terminate: false,
            snackbarOpen: true,
            surveyId: -1,
            changed: false,
            activeStep: 0,
            isValid: true,
            translateX: 0,
            opacity: 1
        };
        this.getActiveStep = this.getActiveStep.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRegisterConfig = this.handleRegisterConfig.bind(this);
        this.handleTerminateConfig = this.handleTerminateConfig.bind(this);
        this.getComments = this.getComments.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.setChanged = this.setChanged.bind(this);
        this.setActiveStep = this.setActiveStep.bind(this);
        this.setIsValid = this.setIsValid.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePageBack = this.handlePageBack.bind(this);
        this.handlePageNext = this.handlePageNext.bind(this);
        this.handleMatrixNext = this.handleMatrixNext.bind(this);
        this.handleMatrixBack = this.handleMatrixBack.bind(this);
    }

    componentDidMount() {
        this.fetchSurvey();
    }

    getData(v, i) {
        if (v)
            return parseInt(v.split('_')[i]);
    }

    getComments(questionId, answerId) {
        let answers = this.state.answers;
        const index = answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
        return (index >= 0) ? answers[index].comments : '';
    }

    isChecked(questionId, answerId) {
        let answers = this.state.answers;
        const checked = answers.some(item => item.questionId === questionId && item.answerId === answerId);
        return checked;
    }

    fetchSurvey = async () => {
        try {
            await axios.get(API_URL + "/api/survey?token=" + this.state.token + "&ro=" + this.state.ro + "&lang=" + this.state.lang)
                .then(response => {
                    this.setState({ "snackbarOpen": false });
                    if (response.data) {
                        let questionsResponse = response.data.questions;
                        let surveyConfig = response.data.surveyConfig;
                        let surveyId = response.data.surveyId;
                        if(surveyId !== -1 && ![102,103,104,105,106,108].includes(surveyId) ){
                            if(surveyId === 145) {
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==6871 && qs.id !==6924).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 6871).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                                questionsResponse.filter(qs =>qs.id === 6924).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                            } else if(surveyId === 47) {
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==1090 && qs.id !==1093).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 1093).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 ) })
                                questionsResponse.filter(qs =>qs.id === 1090).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                            } else if(surveyId === 44){
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==1023).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 1023).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                            } else if(surveyId === 45){
                                questionsResponse = shuffleArray(questionsResponse, 2, 2, 14)
                                questionsResponse = shuffleArray(questionsResponse, 14, 14, 26)
                            } else if(surveyId === 1){
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==4).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 4).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 ) })
                            } else if(surveyId === 60){
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==1486).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 1486).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                            } else if (surveyId === 85) {
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==3155 && qs.id !== 3170).map(q =>  { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 )} )
                                questionsResponse.filter(qs =>qs.id === 3155).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 1, q.answers.length -1, q.answers.length-1 ) })
                                questionsResponse.filter(qs =>qs.id === 3170).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 5, q.answers.length -1, q.answers.length-1 ) })
                            }
                            else if (surveyId === 89) {
                                let last_3_fix  = [3822, 3823, 3824, 3825, 3991]
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && !last_3_fix.includes(qs.id) && qs.id !== 3990 ).map(q =>  { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 )} )
                                questionsResponse.filter(qs =>last_3_fix.includes(qs.id)).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -3, q.answers.length-3 ) })
                                questionsResponse.filter(qs =>qs.id === 3990).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -2, q.answers.length-2 ) })
                            }
                            else if(surveyId === 2){
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX' && qs.id !==14).map(q => customShuffle(q.answers) )
                                questionsResponse.filter(qs =>qs.id === 14).map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 ) })
                            }
                            else {
                                questionsResponse.filter(qs =>qs.type === 'CHECKBOX').map(q =>  { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return shuffleArray(q.answers, 0, q.answers.length -1, q.answers.length-1 )} )
                            }
                        }
                        if (surveyConfig) {
                            // terminate config
                            if (surveyConfig.terminateConfig) {
                                console.log(JSON.stringify(surveyConfig.terminateConfig));
                                surveyConfig.terminateConfig.forEach(tc => {
                                    let terminateConfigQuestionIndex = tc.questionIndex;
                                    let terminateConfigQuestionId = questionsResponse[terminateConfigQuestionIndex].id;
                                    let terminateConfigAnswersIndex = tc.answersIndex;
                                    let terminateQuestionAnswers = questionsResponse[terminateConfigQuestionIndex].answers;
                                    let terminateAnswerIds = [];
                                    terminateConfigAnswersIndex.forEach(e => {
                                        terminateAnswerIds.push(terminateQuestionAnswers[e].id);
                                    });
                                    let answerIds = terminateAnswerIds;
                                    let terminateQuestionConfig = { questionId: terminateConfigQuestionId, answerIds };
                                    questionsResponse[terminateConfigQuestionIndex].terminateConfig = terminateQuestionConfig;
                                    console.log("terminateQuestionConfig: ", JSON.stringify(terminateQuestionConfig));
                                });
                            }

                            
                            // filter config
                            if (surveyConfig.filterConfig) {
                                surveyConfig.filterConfig.forEach(fc => {
                                    let filterConfigQuestionIndex = fc.questionIndex;
                                    let filterQuestionIndex = fc.filterQuestionIndex;
                                    let filterQuestionId = questionsResponse[filterQuestionIndex].id;
                                    let filterConfig = { "filterQuestionId": filterQuestionId };
                                    if(!!fc.otherQuestionIndex) {
                                        let filterOtherQuestionIndex = fc.otherQuestionIndex;
                                        let filterOtherQuestionId = questionsResponse[filterOtherQuestionIndex].id;
                                        if(filterOtherQuestionId) {
                                            filterConfig["filterOtherQuestionId"] = filterOtherQuestionId;
                                        }
                                    }
                                    questionsResponse[filterConfigQuestionIndex].filterConfig = filterConfig;
                                })
                            }
                            // register config
                            if (surveyConfig.registerConfig) {
                                surveyConfig.registerConfig.forEach(rc => {
                                    let registerConfigQuestionIndex = rc.questionIndex;
                                    let otherQuestionIndex = rc.otherQuestionIndex;
                                    let registerVar = rc.registerVar;
                                    let otherQuestionId = questionsResponse[otherQuestionIndex].id;
                                    let registerConfig = { "registerVar": registerVar, "otherQuestionId": otherQuestionId };
                                    questionsResponse[registerConfigQuestionIndex].registerConfig = registerConfig;
                                })
                            }
                            // max answers config
                            if (surveyConfig.maxAnswersConfig) {
                                surveyConfig.maxAnswersConfig.forEach(function (element) {
                                    let maxAnswersConfigQuestionIndex = element.questionIndex;
                                    let maxAnswers = element.maxAnswers;
                                    let maxAnswersConfig = { "maxAnswers": maxAnswers };
                                    questionsResponse[maxAnswersConfigQuestionIndex].maxAnswersConfig = maxAnswersConfig;
                                });
                            }
                        }
                        // logic for single page survey 170
                        let _questionsResponse = questionsResponse;
                        _questionsResponse.map((q, i) => {
                            q.pageNo = [1,2,3].includes(q.stepNo) ? 1 : (_questionsResponse.length-1 === i) ? 3 : 2
                        })
                        console.log(_questionsResponse)
                        this.setState({ token: response.data.token, surveyId: surveyId, status: response.data.status, questions: questionsResponse, answers: response.data.answers, languages: response.data.languages })
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error));
                    this.setState({ status: 'NOTFOUND' });
                });

        } catch (err) {
            console.error(err);
        }
    }

    handleTerminateConfig = (questionId) => {
        let question = this.state.questions.find(q => q.id === questionId && q.terminateConfig);
        if (question) {
            let terminateConfig = question.terminateConfig;
            let answer = this.state.answers.find(item => item.questionId === questionId && terminateConfig.answerIds.includes(item.answerId));
            console.log("answer: ", answer);
            if (answer) {
                return true;
            }
        }
        return false;
    }

    getActiveStep = (prevActiveStep, isNext) => {
        let prevQuestionId = this.state.questions[prevActiveStep].id;
        if (isNext && this.handleTerminateConfig(prevQuestionId)) {
            // Terminate and go to last page
            this.setState({ terminate: true })
            return this.state.questions.length - 1;
        }
        let activeStep = isNext ? prevActiveStep + 1 : prevActiveStep - 1;
        let question = this.state.questions[activeStep];
        if (question.skipLogicEnabled && question.skipLogic && question.skipLogic.length > 0) {
            let skipLogic = question.skipLogic[0];
            let conditionQuestionId = skipLogic.conditionQuestionId;
            let conditionAnswerIds = skipLogic.conditionAnswerIds.split(",").map(Number);
            let answers = this.state.answers;
            let isMatch = false;
            conditionAnswerIds.forEach(function (conditionAnswerId) {
                if (isMatch) return;
                isMatch = answers.some(item => item.questionId === conditionQuestionId && item.answerId === parseInt(conditionAnswerId));
            });
            if (!isMatch) {
                return this.getActiveStep(activeStep, isNext);
            }
        }
        return activeStep;
    }

    validate = (questionId, isRequired) => {
        if (this.state.ro || !isRequired)
            return true;
        let answers = this.state.answers;
        let isValid = answers.some(item => item.questionId === questionId);
        if (!isValid)
            window.scrollTo(0, 0);
        return isValid;
    }

    handleRegisterConfig = (questionId) => {
        let question = this.state.questions.find(q => q.id === questionId && q.registerConfig);
        if (question) {
            let registerConfig = question.registerConfig;
            let varKey = registerConfig.registerVar;
            let answer = this.state.answers.find(item => item.questionId === questionId);
            let selectedOtherAnswer = this.state.answers.find(item => item.questionId === questionId && item.comments !== "");
            let selectedAnswer = question.answers.find(item => item.questionId === questionId && item.id === answer.answerId);
            if (selectedOtherAnswer) {
                this.state.varMap.set(varKey, label({ "ar": selectedOtherAnswer.comments, "en": selectedOtherAnswer.comments }));
                this.setState({ varMap: this.state.varMap });
            } else if (selectedAnswer) {
                this.state.varMap.set(varKey, label(selectedAnswer.label));
                this.setState({ varMap: this.state.varMap });
            } else {
                let other = this.getComments(registerConfig.otherQuestionId, answer.answerId);
                this.state.varMap.set(varKey, other);
                this.setState({ varMap: this.state.varMap });
            }
        }
    }

    handleSubmit = async (questionId, isCompleted, matrix) => {
        let _matrix = (!!matrix) ? matrix : false;
        try {
            // read only
            if (this.state.ro) {
                this.handleRegisterConfig(questionId);
                return;
            }
            if (!this.state.changed && !_matrix)
                return;
            if (!this.validate(questionId))
                return;

            this.handleRegisterConfig(questionId);

            await axios.post(API_URL + "/api/survey/response", { "token": this.state.token.split('&')[0], "questionId": questionId, "isCompleted": isCompleted, "answers": this.state.answers })
                .then(response => {
                    if (response.data) {
                        console.log(JSON.stringify(response.data));
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error))
                });
        } catch (err) {
            console.error(err);
        }
    }

    setChanged(status) {
        this.setState({ changed: status });
    }

    setActiveStep(activeStep) {
        this.setState({ activeStep: activeStep });
    }

    setIsValid(isValid) {
        this.setState({ isValid: isValid });
    }

    OnTransform (val, op) {
        this.setState({
            translateX: val,
            opacity: op
        })
        if(val ===-100) setTimeout(() => {  this.OnTransform(+100, 0); }, 400);
    }
    handleNext  = () =>{
        let isCompleted = (this.state.activeStep ===  this.state.questions.length - 2) ? true : (this.getActiveStep(this.state.activeStep, true) === this.state.questions.length - 1) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired =  this.state.questions[this.state.activeStep].required;
        if (this.validate(questionId, isRequired)) {
            this.setIsValid(true);
            this.handleSubmit(questionId, isCompleted);
            this.setChanged(false);
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, true) }));
        } else {
            this.setIsValid(false);
        }
    };

    handleBack  = () =>{
        let isCompleted = (this.state.activeStep ===  this.state.questions.length - 2) ? true : false;
        let questionId =  this.state.questions[this.state.activeStep].id;
        this.handleSubmit(questionId, isCompleted);
        this.setIsValid(true);
        this.setChanged(false);
        this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, false) }));
    };

    handleMatrixNext = () => {
        let questionsList = [7142,7120,7063,7090,7044,6443,6389,6401,6339,6351,6289,6301,6239,6251,6189,6201,6139,6151,6089,6101,6039,6051,5989,6001,5939,5951,5889,5901,5839,5851,5789,5801,5739,5751,5689,5701,5639,5651,5589,5601,5539,5551,5489,5501,5439,5451,5416,5424,5382,5391,5399,5345,5354,5362,5309,5304,5289,5190,5184,5168,5129,5123,5109,5005,5012,4925,4909,4611,4639,4661,4705,4762,4776,4792,4816, 3333,3157,3230,3251,2513,2526,1030,1043,1493,1501,2115,2126];
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired = this.state.questions[this.state.activeStep].required;
        if(questionsList.includes(questionId)){
            let npsQues = this.state.questions.filter(ques => ques.type === "NPS")
            let radioQues =  (questionId === 7142) ? this.state.questions.slice(3, 29) :
                                (questionId === 7120) ? this.state.questions.slice(3, 20) :
                                (questionId === 7063) ? this.state.questions.slice(1, 26) :
                                (questionId === 7044) ? this.state.questions.slice(3, 19) :
                                (questionId === 6443) ? this.state.questions.slice(5, 15) :
                                (questionId === 5416) ? this.state.questions.slice(7, 15) :
                                (questionId === 5424) ? this.state.questions.slice(15, 23) :
                                (questionId === 5382) ? this.state.questions.slice(10, 19) :
                                (questionId === 5391) ? this.state.questions.slice(19, 27) :
                                (questionId === 5399) ? this.state.questions.slice(27, 35) :
                                (questionId === 5345) ? this.state.questions.slice(10, 19) :
                                (questionId === 5354) ? this.state.questions.slice(19, 27) :
                                (questionId === 5362) ? this.state.questions.slice(27, 35) :
                                (questionId === 5289) ? this.state.questions.slice(77, 89) :
                                (questionId === 5304) ? this.state.questions.slice(92, 96) :
                                (questionId === 5309) ? this.state.questions.slice(97, 113) :
                                (questionId === 5190) ? this.state.questions.slice(99, 115) :
                                (questionId === 5184) ? this.state.questions.slice(94, 98) :
                                (questionId === 5168) ? this.state.questions.slice(79, 91) :
                                (questionId === 5129) ? this.state.questions.slice(41, 49) :
                                (questionId === 5123) ? this.state.questions.slice(36, 41) :
                                (questionId === 5109) ? this.state.questions.slice(23, 35) :
                                (questionId === 5012) ? this.state.questions.slice(15, 23) : 
                                (questionId === 5005) ? this.state.questions.slice(7, 14) : 
                                (questionId === 4925) ? this.state.questions.slice(5, 14) : 
                                (questionId === 4909) ? this.state.questions.slice(6, 15) : 
                                (questionId === 4816) ? this.state.questions.slice(214, 226) : 
                                (questionId === 4792) ? this.state.questions.slice(190, 211) : 
                                (questionId === 4776) ? this.state.questions.slice(174, 187) : 
                                (questionId === 4762) ? this.state.questions.slice(160, 173) : 
                                (questionId === 4705) ? this.state.questions.slice(103, 146) : 
                                (questionId === 4661) ? this.state.questions.slice(59, 102) : 
                                (questionId === 4639) ? this.state.questions.slice(37, 55) : 
                                (questionId === 4611) ? this.state.questions.slice(9, 15) : 
                                (questionId === 3333) ? this.state.questions.slice(5, 13) : 
                                (questionId === 3157) ? this.state.questions.slice(20, 31) : 
                                (questionId === 3230) ? this.state.questions.slice(93, 113) : 
                                (questionId === 3251) ? this.state.questions.slice(114, 134) : [];
            npsQues = ([5639,5689,5739,5789,5839,5889,5939,5989,6039,6089,6139,6189,6239,6289,6339,6389].includes(questionId)) ? npsQues.slice(0, 12) : 
                        ([5651,5701,5751,5801,5851,5901,5951,6001,6051,6101,6151,6201,6251,6301,6351,6401].includes(questionId)) ? npsQues.slice(12, 25) : 
                        (questionId === 7090) ? npsQues.slice(1, 26) : 
                        (questionId === 5589) ? npsQues.slice(0, 12) : 
                        (questionId === 5601) ? npsQues.slice(12, 25) : 
                        (questionId === 5539) ? npsQues.slice(0, 12) : 
                        (questionId === 5551) ? npsQues.slice(12, 25) : 
                        (questionId === 5489) ? npsQues.slice(0, 12) : 
                        (questionId === 5501) ? npsQues.slice(12, 25) : 
                        (questionId === 5439) ? npsQues.slice(0, 12) : 
                        (questionId === 5451) ? npsQues.slice(12, 25) : 
                        (questionId === 2513) ? npsQues.slice(0, 12) : 
                        (questionId === 2526) ? npsQues.slice(12, 25) : 
                        (questionId === 2115) ? npsQues.slice(0, 10) : 
                        (questionId === 2126) ? npsQues.slice(10, 22) : 
                        (questionId === 1493) ? npsQues.slice(0, 9) : 
                        (questionId === 1501) ? npsQues.slice(9, 18) : 
                        (questionId === 1030) ? npsQues.slice(0, 12) : npsQues.slice(12, 25);
          let validateArray = radioQues.length > 0 ? radioQues : npsQues;
          let result = validateArray.map(q => {
            if(this.validate(q.id, true)){
              return true;
            } else {
              return false;
            }
          }) 
          let inValid = result.some((item) => item === false)
          if(!inValid) {
            this.setIsValid(true);
            // Survey 153
            if(questionId === 7142) {
                this.setActiveStep(29);
            }
            // Survey 152
            else if(questionId === 7120) {
                this.setActiveStep(20);
            }
            // Survey 150
            else if(questionId === 7063) {
                this.setActiveStep(26);
            }
            // Survey 151
            else if(questionId === 7090) {
                this.setActiveStep(26);
            }
            // Survey 149
            else if(questionId === 7044) {
                this.setActiveStep(19);
            }
            // Survey 140
            else if(questionId === 6443) {
                this.setActiveStep(15);
            }
            // Survey 124 to 138
            else if([5639,5689,5739,5789,5839,5889,5939,5989,6039,6089,6139,6189,6239,6289,6339,6389].includes(questionId)) {
                this.setActiveStep(13);
            }
            else if([5651,5701,5751,5801,5851,5901,5951,6001,6051,6101,6151,6201,6251,6301,6351,6401].includes(questionId)) {
                this.setActiveStep(26);
            }
            // Survey 123
            else if(questionId === 5589) {
                this.setActiveStep(13);
            }
            else if(questionId === 5601) {
                this.setActiveStep(26);
            }
            // Survey 122
            else if(questionId === 5539) {
                this.setActiveStep(13);
            }
            else if(questionId === 5551) {
                this.setActiveStep(26);
            }
            // Survey 121
            else if(questionId === 5489) {
                this.setActiveStep(13);
            }
            else if(questionId === 5501) {
                this.setActiveStep(26);
            }
            // Survey 120
            else if(questionId === 5439) {
                this.setActiveStep(13);
            }
            else if(questionId === 5451) {
                this.setActiveStep(26);
            }
            // Survey 119
            else if(questionId === 5416) {
                this.setActiveStep(15);
            } else if(questionId === 5424) {
                this.setActiveStep(23);
            }
            // Survey 117
            else if(questionId === 5345) {
                this.setActiveStep(19);
            } else if(questionId === 5354) {
                this.setActiveStep(27);
            } else if(questionId === 5362) {
                this.setActiveStep(35);
            }
              // Survey 118
              else if(questionId === 5382) {
                this.setActiveStep(19);
            } else if(questionId === 5391) {
                this.setActiveStep(27);
            } else if(questionId === 5399) {
                this.setActiveStep(35);
            }
            // Survey 114
            else if(questionId === 5289) {
                this.setActiveStep(89);
            } else if(questionId === 5304) {
                this.setActiveStep(96);
            } else if(questionId === 5309) {
                this.setActiveStep(113);
            }
            // Survey 115
            else if(questionId === 5109) {
                this.setActiveStep(35);
            } else if(questionId === 5123) {
                this.setActiveStep(41);
            } else if(questionId === 5129) {
                this.setActiveStep(49);
            } else if(questionId === 5168) {
                this.setActiveStep(91);
            } else if(questionId === 5184) {
                this.setActiveStep(98);
            } else if(questionId === 5190) {
                this.setActiveStep(115);
            }
            // Survey 109
            else if(questionId === 5012) {
                this.setActiveStep(23);
            }
            else if(questionId === 5005) {
                this.setActiveStep(14);
            }
            // Survey 98
            else if(questionId === 4925) {
                this.setActiveStep(14);
            }
            // Survey 97
            else if(questionId === 4909) {
                this.setActiveStep(15);
            }
            // Survey 94
            else if(questionId === 4816) { 
                this.setActiveStep(226);
            }
            else if(questionId === 4792) {
                this.setActiveStep(211);
            }
            else if(questionId === 4776) {
                this.setActiveStep(187);
            }
            else if(questionId === 4762) {
                this.setActiveStep(173);
            }
            else if(questionId === 4705) {
                this.setActiveStep(146);
            }
            else if(questionId === 4661) {
                this.setActiveStep(102);
            }
            else if(questionId === 4639) {
                this.setActiveStep(55);
            }
            else if(questionId === 4611) {
                this.setActiveStep(15);
            }
            //
            else if(questionId === 3333) {
                this.setActiveStep(14);
            }
            else if(questionId === 3157) {
                this.setActiveStep(31);
            }
            else if(questionId === 3230) {
                this.setActiveStep(113);
            }
            else if(questionId === 3251) {
                this.setActiveStep(134);
            }
            else if(questionId === 1493) {
                this.setActiveStep(19);
            }
            else if(questionId === 1501) {
                this.setActiveStep(29);
            }
            else if(questionId === 1030) {
                this.setActiveStep(14);
            }
            else if(questionId === 2513) {
                this.setActiveStep(14);
            }
            else if(questionId === 2526) {
                this.setActiveStep(26);
            }
            else if(questionId === 2115) {
                this.setActiveStep(15);
            }
            else if(questionId === 2126) {
                this.setActiveStep(27);
            } else this.setActiveStep(27);
          } else {
            this.setIsValid(false);
          }
        } else {
          if (this.validate(questionId, isRequired)) {
            this.setIsValid(true);
            this.handleSubmit(questionId, isCompleted);
            this.setChanged(false);
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, true) }));
          } else {
            this.setIsValid(false);
          }
        }
      };

      handleMatrixBack = () => {
        this.setIsValid(true);
        if(this.state.surveyId === 153 && this.state.activeStep === 29){
            this.setActiveStep(3);
        }
        else if(this.state.surveyId === 152 && this.state.activeStep === 20){
            this.setActiveStep(3);
        }
        else if(this.state.surveyId === 150 && this.state.activeStep === 26){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 151 && this.state.activeStep === 26){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 149 && this.state.activeStep === 19){
            this.setActiveStep(3);
        }
        else if(this.state.surveyId === 140 && this.state.activeStep === 15){
            this.setActiveStep(5);
        }
        else if([124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139].includes(this.state.surveyId) && this.state.activeStep === 13){
            this.setActiveStep(1);
        }
        else if([124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139].includes(this.state.surveyId) && this.state.activeStep === 26){
            this.setActiveStep(13);
        }
        else if(this.state.surveyId === 123 && this.state.activeStep === 13){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 123 && this.state.activeStep === 26){
            this.setActiveStep(13);
        }
        else if(this.state.surveyId === 122 && this.state.activeStep === 13){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 122 && this.state.activeStep === 26){
            this.setActiveStep(13);
        }
        else if(this.state.surveyId === 121 && this.state.activeStep === 13){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 121 && this.state.activeStep === 26){
            this.setActiveStep(13);
        }
        else if(this.state.surveyId === 120 && this.state.activeStep === 13){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 120 && this.state.activeStep === 26){
            this.setActiveStep(13);
        }
        else if(this.state.surveyId === 119 && this.state.activeStep === 15){
            this.setActiveStep(7);
        }
        else if(this.state.surveyId === 119 && this.state.activeStep === 23){
            this.setActiveStep(15);
        }
        else if(this.state.surveyId === 117 && this.state.activeStep === 19){
            this.setActiveStep(10);
        }
        else if(this.state.surveyId === 117 && this.state.activeStep === 27){
            this.setActiveStep(19);
        }
        else if(this.state.surveyId === 117 && this.state.activeStep === 35){
            this.setActiveStep(27);
        }
        else if(this.state.surveyId === 118 && this.state.activeStep === 19){
            this.setActiveStep(10);
        }
        else if(this.state.surveyId === 118 && this.state.activeStep === 27){
            this.setActiveStep(19);
        }
        else if(this.state.surveyId === 118 && this.state.activeStep === 35){
            this.setActiveStep(27);
        }
        else if(this.state.surveyId === 114 && this.state.activeStep === 89){
            this.setActiveStep(77);
        }
        else if(this.state.surveyId === 114 && this.state.activeStep === 96){
            this.setActiveStep(92);
        }
        else if(this.state.surveyId === 114 && this.state.activeStep === 113){
            this.setActiveStep(97);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 35){
            this.setActiveStep(23);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 41){
            this.setActiveStep(36);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 49){
            this.setActiveStep(41);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 91){
            this.setActiveStep(79);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 98){
            this.setActiveStep(94);
        }
        else if(this.state.surveyId === 115 && this.state.activeStep === 115){
            this.setActiveStep(99);
        }
        else if(this.state.surveyId === 109 && this.state.activeStep === 23){
            this.setActiveStep(14);
        }
        else if(this.state.surveyId === 109 && this.state.activeStep === 14){
            this.setActiveStep(7);
        }
        else if(this.state.surveyId === 98 && this.state.activeStep === 14){
            this.setActiveStep(5);
        }
        else if(this.state.surveyId === 97 && this.state.activeStep === 15){
            this.setActiveStep(6);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 15){
            this.setActiveStep(9);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 55){
            this.setActiveStep(37);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 102){
            this.setActiveStep(59);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 146){
            this.setActiveStep(103);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 173){
            this.setActiveStep(160);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 187){
            this.setActiveStep(174);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 211){
            this.setActiveStep(190);
        }
        else if(this.state.surveyId === 94 && this.state.activeStep === 226){
            this.setActiveStep(214);
        }
        else if(this.state.surveyId === 86 && this.state.activeStep === 14){
            this.setActiveStep(5);
        }
        else if(this.state.surveyId === 85 && this.state.activeStep === 31){
            this.setActiveStep(20);
        }
        else if(this.state.surveyId === 85 && this.state.activeStep === 113){
            this.setActiveStep(93);
        }
        else if(this.state.surveyId === 85 && this.state.activeStep === 134){
            this.setActiveStep(114);
        }
        else if(this.state.surveyId === 81 && this.state.activeStep === 14){
            this.setActiveStep(1);
        }
        else if(this.state.surveyId === 81 && this.state.activeStep === 26){
            this.setActiveStep(14);
        }
        else if(this.state.surveyId === 45 && this.state.activeStep === 14){
            this.setActiveStep(2);
        }
        else if(this.state.surveyId === 45 && this.state.activeStep === 27){
            this.setActiveStep(14);
        }
        else if(this.state.surveyId === 78 && this.state.activeStep === 15){
            this.setActiveStep(5);
        }
        else if(this.state.surveyId === 78 && this.state.activeStep === 27){
            this.setActiveStep(15);
        }
        else if(this.state.surveyId === 60 && this.state.activeStep === 19){
            this.setActiveStep(11);
        }
        else if(this.state.surveyId === 60 && this.state.activeStep === 29){
            this.setActiveStep(19);
        }
        else {
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, false) }));
        }
    };

    handlePageNext = () => {
        let questionsList = [7460, 7463];
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired = this.state.questions[this.state.activeStep].required;
        if(questionsList.includes(questionId)){
          let _ques = (questionId === 7460) ? this.state.questions.slice(0, 3) : this.state.questions.slice(3, 15) 
          let validateArray = _ques;
          let result = validateArray.map(q => {
            console.log(q)
            if(this.validate(q.id, q.required)){
                let _isCompleted = (q.stepNo === this.state.questions.length - 1) ? true : false;
                this.handleSubmit(q.id, _isCompleted);
                return true;
            } else {
              return false;
            }
          }) 
          let inValid = result.some((item) => item === false)
          if(!inValid) {
            this.setIsValid(true);
            
            // Survey 170
            if([7460, 7461, 7462].includes(questionId)) {
                this.setActiveStep(3);
            } 
            else if(![7460, 7461, 7462].includes(questionId)) {
                this.setActiveStep(15);
            } 
            else this.setActiveStep(15);
          } else {
            this.setIsValid(false);
          }
        } else {
          if (this.validate(questionId, isRequired)) {
            this.setIsValid(true);
            this.handleSubmit(questionId, isCompleted);
            this.setChanged(false);
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, true) }));
          } else {
            this.setIsValid(false);
          }
        }
      };

      handlePageBack = () => {
        this.setIsValid(true);
        if(this.state.surveyId === 170 && this.state.activeStep === 3){
            this.setActiveStep(0);
        }
        else {
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, false) }));
        }
    };

    handleChange(event, v, qId, isMobile) {
        this.setChanged(true);
        let value = event.target.value;
        let id = event.target.id;
        let type = event.target.type;

        type = (type == null || type === 'button') ? 'radio' : type;
        value = (value == null) ? v : value;
        let questionId = (type === 'textarea') ? this.getData(id, 0) : this.getData(value, 0);
        let answerId = (type === 'textarea') ? this.getData(id, 1) : this.getData(value, 1);
        let question = this.state.questions.find(q => q.id === questionId);

        let comments = '';
        let _answers = this.state.answers;

        if (type === 'radio' && questionId == null) {
            const index = _answers.findIndex((item => item.questionId === qId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

        if (type === 'radio' || question.type === "RADIO") {
            const index = _answers.findIndex((item => item.questionId === questionId));
            index >= 0 && _answers.splice(index, 1);
        }
        if (type === 'textarea') {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            comments = value;
        }

        if (type === 'checkbox' && event.target.checked === true) {
            if (question.maxAnswersConfig) {
                let maxAnswersConfig = question.maxAnswersConfig;
                const selectedAnswers = _answers.filter((item => item.questionId === questionId));
                if (selectedAnswers.length > (maxAnswersConfig.maxAnswers - 1)) {
                    return;
                }
            }
        }

        if (type === 'checkbox' && event.target.checked === false) {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

        let _answer = { 'questionId': questionId, 'answerId': answerId, 'comments': comments }
        _answers.push(_answer);
        this.setState({ answers: _answers });
        if (!question.comments && ((question.type === "RADIO"   && question.surveyId !== 153 && question.surveyId !== 152 && question.surveyId !== 150 && question.surveyId !== 149 && question.surveyId !== 140 && question.surveyId !== 119 && question.surveyId !== 118 && question.surveyId !== 117 && question.surveyId !== 114 && question.surveyId !== 115 && question.surveyId !== 109 && question.surveyId !== 85 && question.surveyId !== 86 && question.surveyId !== 88 && question.surveyId !== 94 && question.surveyId !== 97 && question.surveyId !== 98) || question.type === "SMILEY" || question.surveyId === 170 || ((question.type === "NPS" && ![120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,151].includes(question.surveyId)  && question.surveyId !== 81 && question.surveyId !== 45 && question.surveyId !== 60 && question.surveyId !== 78) || !!isMobile))) {
            setTimeout(() => {  this.OnTransform(-100, 0); }, 300);
            setTimeout(() => {  this.handleNext();this.OnTransform(0, 1); }, 950);
        }
        return true;
    }
    render() {
        return (
            <>
                <Box width="100%" display="flex" alignItems="left" justifyContent="left">
                    <TopBar token={this.state.token} surveyId={this.state.surveyId} ro={this.state.ro} lang={this.state.lang} languages={this.state.languages} />
                    {
                        this.state.status === 'VALID' && this.state.questions && this.state.questions.length > 0 &&
                        <ProgressMobileStepper ro={this.state.ro} survey={this.state.surveyId} getActiveStep={this.getActiveStep} validate={this.validate} handleSubmit={this.handleSubmit} getComments={this.getComments} isChecked={this.isChecked} handleChange={this.handleChange} questions={this.state.questions} varMap={this.state.varMap} answers={this.state.answers} terminate={this.state.terminate} activeStep={this.state.activeStep} isValid={this.state.isValid} handleMatrixNext={this.handleMatrixNext} handleMatrixBack={this.handleMatrixBack} handleBack={this.handleBack} handleNext={this.handleNext} opacity={this.state.opacity} translateX = {this.state.translateX} handlePageNext={this.handlePageNext} handlePageBack={this.handlePageBack} />
                    }
                    {
                        this.state.status === 'EXPIRED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                            {label(labels.get("survey_expired"))}
                        </Typography>
                    }
                    {
                        this.state.status === 'COMPLETED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                            {label(labels.get("survey_completed"))}
                        </Typography>
                    }
                    {
                        this.state.status === 'NOTFOUND' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                            {label(labels.get("survey_notfound"))}
                        </Typography>
                    }
                    {
                        this.state.status === 'INVALID' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                            {label(labels.get("survey_notfound"))}
                        </Typography>
                    }
                </Box>
                <Box>
                    <CustomizedSnackbar
                        open={this.state.snackbarOpen}
                    />
                </Box>
            </>
        );
    }
}

export default Survey;
