import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import content from 'src/utils/ContentUtil';
import Question from './Question';
import Box from '@material-ui/core/Box'
import { green } from "@material-ui/core/colors";
import Alert from '@material-ui/lab/Alert';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import { useMediaQuery } from "@material-ui/core";

export default function ProgressMobileStepper({ ro, survey, handleSubmit, getComments, isChecked, handleChange, questions, varMap, answers, terminate, handleBack, handleNext, activeStep, handleMatrixNext, handleMatrixBack, isValid, translateX, opacity, handlePageNext, handlePageBack  }) {
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      '& .MuiMobileStepper-progress': {
        backgroundColor: survey === 118 ? 'blue' : (survey === 119 || survey === 160) ? '#fdd835' : green[200]
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: survey === 118 ? 'blue' : (survey === 119 || survey === 160) ? '#ffaa01' : green[600]
      },
    }
  });
  const classes = useStyles();
  const isDesktopOrLaptop = useMediaQuery('(min-width: 1120px)');
  const styles ={
    transition: 'all 0.4s ease-in-out'
}
console.log(activeStep)
  let matrixSurveys = [45,60,78,81,85,86,88,94,97,98,109,115,114,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,149,151,150,152,153]
  let minLen = ( activeStep === 5) ? 12 : 5;
  let disableNext = (activeStep === questions.length - 1) || 
      (survey===64 && ((activeStep === 2 && (answers[activeStep -1 ] ? answers[activeStep - 1].comments.length !== 12 : ( activeStep === 2))))) || 
      (survey===70 && ((activeStep === 6 && (answers[activeStep] ? answers[activeStep].comments.length !== 12 : answers[answers.length -1].comments.length !== 12)))) || 
      (survey===61 && ((activeStep === 5 || activeStep === 6)  && answers[activeStep] ? answers[activeStep].comments.length !== minLen : ( activeStep === 5 || activeStep === 6)))
  //single page survey logic
  let pageSurveys = [170], isPage = false;
  isPage = pageSurveys.includes(survey) ? true : false;
  //signle page survey
  let pageEnable = false, pageQuestions = questions;
  if(survey === 170 && activeStep === 0) {
      pageEnable = true
      pageQuestions = questions.filter(q => q.pageNo === 1)
  } else if(survey === 170 && activeStep === 3 ) {
      pageEnable = true
      pageQuestions = questions.filter(q => q.pageNo === 2)
  } else if(survey === 170 && activeStep === 15 ) {
    pageEnable = true
    pageQuestions = questions.filter(q => q.pageNo === 3)
  }
  return (
    <Box width="100%" justifyItems="center" style = {{...styles, transform: `translate(${translateX}%, 0%)`, opacity: opacity}}>
      {!isValid && <Box p={2}><Alert variant="outlined" severity="error">
        {(matrixSurveys.includes(survey) && isDesktopOrLaptop) ? label(labels.get("please_answer_all_this_questions")) : label(labels.get("please_answer_this_question"))}
      </Alert> </Box>}
      {!!isPage && pageQuestions.map((ques,i) => <Question key={i} ro={ro} survey={survey} getComments={getComments} isChecked={isChecked} handleSubmit={handleSubmit} handleChange={handleChange} question={questions[ques.stepNo - 1]} varMap={varMap} answers={answers} questions={questions} terminate={terminate} />) }
      {!isPage && <Question ro={ro} survey={survey} getComments={getComments} isChecked={isChecked} handleSubmit={handleSubmit} handleChange={handleChange} question={questions[activeStep]} varMap={varMap} answers={answers} questions={questions} terminate={terminate} />}
      {activeStep < questions.length - 1 ?
        <Box width="100%" p={2} justifyItems="center">
          <MobileStepper
            variant="progress"
            steps={questions.length}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            style={{width:'100%'}}
            nextButton={
              <Button size="small" onClick={( matrixSurveys.includes(survey) && isDesktopOrLaptop) ? handleMatrixNext : (pageSurveys.includes(survey)) ? handlePageNext : handleNext} disabled={disableNext}>
                {label(labels.get("next"))}
                {content(<KeyboardArrowRight />, <KeyboardArrowLeft />)}
              </Button>
            }
            backButton={activeStep > 0 ?
              <Button size="small" onClick={(matrixSurveys.includes(survey) && isDesktopOrLaptop) ? handleMatrixBack : (pageSurveys.includes(survey)) ? handlePageBack : handleBack} disabled={activeStep === 0}>
                {content(<KeyboardArrowLeft />, <KeyboardArrowRight />)}
                {label(labels.get("back"))}
              </Button> : null
            }
          />
        </Box>
        : null}
    </Box>
  );
}
